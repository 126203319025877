import { Button, type ButtonProps } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import classes from './BackButton.module.css';

interface Props extends ButtonProps {
  children?: React.ReactNode;
  onClick: () => void;
}

export function BackButton({ children, onClick, ...others }: Props) {
  return (
    <Button
      variant={'subtle'}
      size={'md'}
      className={classes.backButton}
      styles={{ section: { marginRight: 5 } }}
      leftSection={<IconArrowLeft size={16} stroke={2} />}
      onClick={onClick}
      {...others}
    >
      {children}
    </Button>
  );
}
