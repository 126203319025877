import { type INavLink, NavLink } from '@/components/Nav/NavLink';
import { RocketIcon } from '@/components/icons/RocketIcon';
import { useStakingChainConfig } from '@/components/pages/Staking/state/staking.hooks';
import { useIsAppchainViewEnabledFlag } from '@/hooks/flags/useIsAppchainViewEnabledFlag';
import { useIsDancelightEnabledFlag } from '@/hooks/flags/useIsDancelightEnabledFlag';
import { Box, ScrollArea, type ScrollAreaProps } from '@mantine/core';
import {
  type Icon,
  IconDeviceDesktop,
  IconHome,
  type IconProps,
  IconSchool,
  IconTopologyFull,
} from '@tabler/icons-react';
import { useMemo } from 'react';

const BASE_NAV_LINKS = [
  {
    label: 'Home',
    path: '/',
    icon: IconHome,
  },
  {
    label: 'Launch Terminal',
    icon: RocketIcon as React.ForwardRefExoticComponent<
      IconProps & React.RefAttributes<Icon>
    >,
    links: [
      {
        label: 'My Dashboard',
        path: '/dashboard',
      },
      {
        label: 'Launch Network',
        path: '/create',
      },
      {
        label: 'Demo Network',
        path: '/demo',
      },
    ],
  },
  {
    label: 'Ecosystem',
    icon: IconTopologyFull,
    links: [
      {
        label: 'All Networks',
        path: '/networks',
      },
      {
        label: 'Staking',
        path: '/staking',
      },
    ],
  },
  {
    label: 'Learn',
    icon: IconSchool,
    links: [
      {
        label: 'Tutorials',
        path: 'https://youtube.com/watch?v=Ovasc7uAXpk&list=PLEgIGlavjXIjiFacNWXn2Bmk4R1F_B8LB/',
      },
      {
        label: 'Docs',
        path: 'https://docs.tanssi.network/',
      },
    ],
  },
  {
    label: 'Dev Support',
    path: 'https://discord.gg/tw7muAspeW',
    icon: IconDeviceDesktop,
  },
] satisfies INavLink[];

interface Props extends ScrollAreaProps {
  onClick?: () => void;
}

export function NavLinks({ onClick, ...others }: Props) {
  const { isEnabled: isAppchainViewEnabled } = useIsAppchainViewEnabledFlag();
  const { isEnabled: isDancelightEnabled } = useIsDancelightEnabledFlag();
  const { selectedChainKey, config } = useStakingChainConfig();

  const navLinks = useMemo(
    () =>
      BASE_NAV_LINKS.map((link) => {
        if (link.label === 'Ecosystem') {
          return {
            ...link,
            links: link.links?.map((subLink) => ({
              ...subLink,
              path:
                subLink.label === 'Staking'
                  ? isDancelightEnabled && !selectedChainKey
                    ? '/staking'
                    : `/${config.key}/staking`
                  : subLink.path,
            })),
          };
        }

        return link;
      }).filter(({ path }) => {
        if (path === '/networks') {
          return isAppchainViewEnabled;
        }

        // Appchains fallback
        if (path === 'https://dashboard.tanssi-chains.network/') {
          return !isAppchainViewEnabled;
        }

        return true;
      }),
    [selectedChainKey, config.key, isDancelightEnabled, isAppchainViewEnabled],
  );

  return (
    <ScrollArea {...others}>
      <Box py={'lg'} px={8}>
        {navLinks.map((link) => {
          return <NavLink key={link.label} onClick={onClick} {...link} />;
        })}
      </Box>
    </ScrollArea>
  );
}
