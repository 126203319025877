import type { PublicAppchain } from '@/shared/db/entities/appchains';
import { Combobox, Group, Text, TextInput, useCombobox } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { useMemo, useState } from 'react';
import { AppchainIdenticon } from '../AppchainIdenticon';

export interface Props {
  selectedAppchain: PublicAppchain | undefined;
  appchains: PublicAppchain[];
  onSelect: (appchain: PublicAppchain | undefined) => void;
}

export function SelectAppchain({
  selectedAppchain,
  appchains,
  onSelect,
}: Props) {
  const [search, setSearch] = useState('');
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const filteredOptions = useMemo(
    () =>
      appchains
        .map((appchain) => {
          return {
            ...appchain,
            chainName: appchain.name || appchain.paraId.toString(),
          };
        })
        .filter(
          (appchain) =>
            appchain.chainName
              .toLowerCase()
              .trim()
              .includes(search.toLowerCase().trim()) ||
            appchain.paraId.toString().includes(search.toLowerCase().trim()),
        )
        .sort((appchainA, appchainB) =>
          appchainA.chainName.localeCompare(appchainB.chainName),
        ),
    [appchains, search],
  );

  const options = useMemo(
    () =>
      filteredOptions.map((appchain) => (
        <Combobox.Option value={appchain.chainName} key={appchain.chainName}>
          <Group>
            <AppchainIdenticon
              logoUrl={appchain.logo}
              paraId={appchain.paraId}
            />
            <Text>{appchain.chainName}</Text>
          </Group>
        </Combobox.Option>
      )),
    [filteredOptions],
  );

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(val) => {
        onSelect(
          appchains.find(
            (appchain) =>
              appchain.name === val || appchain.paraId.toString() === val,
          ),
        );
        setSearch(val);
        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <TextInput
          size={'lg'}
          value={search}
          onChange={(event) => {
            combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
            setSearch(
              event.currentTarget.value ? event.currentTarget.value : '',
            );
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => {
            combobox.closeDropdown();
            setSearch(
              selectedAppchain
                ? selectedAppchain.name || selectedAppchain.paraId.toString()
                : '',
            );
          }}
          placeholder={'Select Network'}
          leftSection={
            selectedAppchain && (
              <AppchainIdenticon
                logoUrl={selectedAppchain.logo}
                paraId={selectedAppchain.paraId}
                size={24}
              />
            )
          }
          rightSection={<IconChevronDown size={15} />}
          rightSectionPointerEvents={'none'}
        />
      </Combobox.Target>

      <Combobox.Dropdown bg={'dark.9'} style={{ border: 'none' }}>
        <Combobox.Options>
          {options.length > 0 ? (
            options
          ) : (
            <Combobox.Empty>{'Nothing found'}</Combobox.Empty>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}
