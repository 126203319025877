import type { ChainConfig } from '@/config';
import { useAppchainUrls } from '@/hooks/polkadot/appchain';
import { isNetworkUp } from '@/utils/polkadot';
import { useQuery } from '@tanstack/react-query';

const FIVE_MINUTES = 1000 * 60 * 5;

export function useIsAppchainUp(
  paraId: number | undefined,
  config: ChainConfig | undefined,
) {
  const { urls } = useAppchainUrls(paraId, config);

  const { data, isLoading } = useQuery({
    queryKey: ['isAppchainUp', urls?.http, paraId],
    queryFn: async () => await isNetworkUp(urls?.http || ''),
    enabled: !!urls?.http,
    staleTime: FIVE_MINUTES,
  });

  return { isUp: !!data, isLoading };
}
