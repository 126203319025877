import { SidebarHeader } from '@/components/Sidebar';
import { SidebarButtons } from '@/components/Sidebar/SidebarButtons';
import {
  ManageAppchainTab,
  getTabInfo,
} from '../state/ManageAppchain.constants';

const buttons = [
  ManageAppchainTab.BlockProductionTopUp,
  ManageAppchainTab.BlockProductionTip,
].map(getTabInfo);

export function BlockProduction() {
  return (
    <>
      <SidebarHeader
        title={getTabInfo(ManageAppchainTab.BlockProduction).title}
        goBack={ManageAppchainTab.Management}
      />
      <SidebarButtons buttons={buttons} />
    </>
  );
}
