import { theme } from '@/theme';
import { type BadgeProps, Badge as MantineBadge } from '@mantine/core';

export const VARIANT = {
  teal: {
    bg: theme.colors?.dark?.[6],
    c: theme.colors?.tanssiTeal?.[9],
  },
  orange: {
    bg: theme.other?.colors?.licorice,
    c: theme.other?.colors?.lightSalmon,
  },
  yellow: {
    bg: theme.other?.colors?.cafeNoir,
    c: theme.other?.colors?.saffron,
  },
  blue: {
    bg: theme.other?.colors?.deepIndigo,
    c: theme.other?.colors?.blue,
  },
  green: {
    bg: theme.other?.colors?.darkGreen,
    c: theme.other?.colors?.lightGreen,
  },
};

export interface Props extends BadgeProps {
  children: React.ReactNode;
  variant?: keyof typeof VARIANT;
}

export function Badge({ variant = 'teal', children, ...others }: Props) {
  return (
    <MantineBadge
      p={4}
      fz={10}
      lh={1.2}
      bg={VARIANT[variant].bg}
      c={VARIANT[variant].c}
      style={{ borderRadius: 6 }}
      {...others}
    >
      {children}
    </MantineBadge>
  );
}
