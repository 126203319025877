import { useBlockStagenetsInProd } from '@/hooks/useBlockStagenetsInProd';
import { AppShell, type AppShellMainProps, Group, Stack } from '@mantine/core';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';

interface Props extends AppShellMainProps {
  children: ReactNode;
}

export function Main({ pr, children }: Props) {
  const router = useRouter();

  useBlockStagenetsInProd();

  return (
    <AppShell.Main pr={pr}>
      <Group
        mah={'calc(100vh - var(--header-height))'}
        style={{ flex: 1, overflowY: 'visible' }}
      >
        <Stack
          px={{ base: 'sm', md: 'xl' }}
          py={30}
          mx={'auto'}
          miw={300}
          maw={router.route === '/[chainKey]/staking' ? 1400 : 1100}
          gap={0}
          w={'100%'}
        >
          {children}
        </Stack>
      </Group>
    </AppShell.Main>
  );
}
