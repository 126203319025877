import { rem } from '@mantine/core';

interface RocketIconProps extends React.ComponentPropsWithoutRef<'svg'> {}

export function RocketIcon({
  width = 19,
  height = 19,
  style,
  stroke,
  color,
  ...others
}: RocketIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      style={{ width: rem(width), height: rem(height), ...style }}
      stroke={stroke}
      {...others}
    >
      <path
        d="M9.08594 10.3051C13.2496 8.48939 14.7091 4.24687 14.3613 1.43375C14.3613 1.43375 14.3208 1.16602 14.1555 0.999747C13.9903 0.833476 13.7243 0.792637 13.7243 0.792637C11.3925 0.500502 8.07118 1.47303 5.98641 4.23596M9.08594 10.3051C8.43297 10.5898 7.71349 10.8149 6.92405 10.9654L4.25121 8.27531C4.40074 7.48077 4.62441 6.7567 4.9074 6.09959M9.08594 10.3051L4.9074 6.09959M4.9074 6.09959C5.20658 5.40491 5.57208 4.78508 5.98641 4.23596M5.98641 4.23596L3.43297 4.61565L1.68043 7.2762L4.41693 7.53454M10.9383 9.21984L10.5611 11.7898L7.91759 13.5536L7.66091 10.7994M9.43353 3.51048C8.811 4.13703 8.80495 5.14677 9.42002 5.76581C10.0351 6.38484 11.0384 6.37875 11.6609 5.75221C12.2834 5.12566 12.2895 4.11592 11.6744 3.49688C11.0593 2.87785 10.0561 2.88394 9.43353 3.51048Z"
        stroke={color}
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.69141 12.2031L4.96746 12.9317M4.35488 10.858L3.01836 12.2031M3.01836 9.51283L2.29441 10.2415"
        stroke={color}
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
