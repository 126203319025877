import { SidebarHeader } from '@/components/Sidebar';
import { ProxyOrSudoSidebarAlert } from '@/components/pages/Dashboard/ManageAppchain/ProxyOrSudoSidebarAlert';
import {
  type ManageAppchainTab,
  getTabInfo,
} from '@/components/pages/Dashboard/ManageAppchain/state/ManageAppchain.constants';
import { useAppchainSudo } from '@/hooks/polkadot/appchain';
import type { ReactNode } from 'react';

interface Props {
  tab: ManageAppchainTab;
  children: ReactNode | ((props: { hasSudoRights: boolean }) => ReactNode);
  goBack?: ManageAppchainTab;
}

export function SudoSidebarWrapper({ tab, children, goBack }: Props) {
  const { isLoading, hasSudoRights } = useAppchainSudo();

  const renderChildren = () =>
    typeof children === 'function' ? children({ hasSudoRights }) : children;

  return (
    <>
      <SidebarHeader title={getTabInfo(tab).title} goBack={goBack} showProxy />
      <ProxyOrSudoSidebarAlert />
      {!isLoading && renderChildren()}
    </>
  );
}
