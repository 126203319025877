import { getPolkadotHttpApi } from '@/utils/polkadot';
import { useQuery } from '@tanstack/react-query';

async function getHasProxies(http: string | undefined) {
  if (!http) {
    return false;
  }

  const api = await getPolkadotHttpApi(http);

  if (!api) {
    return false;
  }

  return !!api.query?.proxy?.proxies && !!api?.tx?.proxy;
}

export function useHasAppchainProxy(http: string | undefined): {
  isLoading: boolean;
  hasProxy: boolean | undefined;
} {
  const { data: hasProxy, isLoading } = useQuery({
    queryKey: ['hasAppchainProxy', http],
    queryFn: async () => getHasProxies(http),
    enabled: !!http,
  });

  return { isLoading, hasProxy };
}
