import type { ChainConfig } from '@/config/config.interfaces';
import type {
  PendingOperation,
  PendingOperationQuery,
  PendingOperationType,
} from '@/hooks/polkadot/staking/staking.interfaces';
import { AssetAmount } from '@moonbeam-network/xcm-types';
import type { StorageKey, u32 } from '@polkadot/types';
import type { AccountId32 } from '@polkadot/types/interfaces';
import type { PalletPooledStakingPendingOperationKey } from '@polkadot/types/lookup';
import type { Codec } from '@polkadot/types/types';

export function mapToPendingOperationQuery({
  delegator,
  type,
  candidate,
  at,
}: PendingOperation): PendingOperationQuery {
  return {
    delegator,
    operation: {
      [type]: {
        candidate,
        at,
      },
    },
  } as PendingOperationQuery;
}

export function transformPendingOperations(
  config: ChainConfig,
  data: [
    StorageKey<[AccountId32, PalletPooledStakingPendingOperationKey]>,
    Codec,
  ][],
): PendingOperation[] {
  return data.map(([key, value]) => {
    const converted = key.toHuman() as [
      string,
      Record<PendingOperationType, { at: string; candidate: string }>,
    ];

    const [delegator, operation] = converted;
    const type = Object.keys(operation)[0] as PendingOperationType;
    const { candidate, at } = operation[type];

    return {
      id: key.toHex(),
      type,
      delegator,
      candidate,
      amount: AssetAmount.fromAsset(config.asset, {
        amount: BigInt(value.toString()),
        decimals: config.decimals,
      }),
      at: +at.replaceAll(',', ''),
    };
  });
}

export function transformSession(session: u32): number {
  return +session.toString().replaceAll(',', '');
}
