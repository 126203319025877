import type { ChainConfig } from '@/config';
import { useApi } from '@/hooks/polkadot/useApi';
import { useApiCall } from '@/hooks/polkadot/useApiCall';
import { isDefined } from '@/utils/common';
import type { u32 } from '@polkadot/types';
import { useMemo } from 'react';

export function useAppchainTip(
  paraId: number | undefined,
  config: ChainConfig | undefined,
) {
  const api = useApi(config?.ws);
  const tip = useApiCall(
    api?.query.servicesPayment.maxTip,
    [paraId],
    transform,
  );

  return useMemo(() => {
    if (!isDefined(tip) || !config) {
      return undefined;
    }

    return config.getAssetAmount(tip);
  }, [tip, config]);
}

function transform(tip: u32): bigint {
  return tip.toBigInt();
}
