import { OffsiteLink } from '@/components/OffsiteLink';
import { NavLink as NavLinkNext, type NavLinkProps } from '@mantine/core';
import type { Icon, IconProps } from '@tabler/icons-react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { ForwardRefExoticComponent, RefAttributes } from 'react';
import classes from './Nav.module.css';

export interface INavLink {
  label: string;
  icon?: ForwardRefExoticComponent<IconProps & RefAttributes<Icon>>;
  path?: string;
  links?: INavLink[];
}

interface Props extends INavLink, Omit<NavLinkProps, 'label'> {}

export function NavLink({
  path = '',
  label,
  icon: Icon,
  links,
  onClick,
  ...other
}: Props) {
  const router = useRouter();
  const isOffsiteLink = path?.startsWith('https://');

  const isActive =
    router.pathname === path ||
    (path !== '/' && router.pathname.includes(path));

  const icon = Icon ? (
    <Icon
      size={22}
      stroke={'1.5'}
      color={'var(--mantine-color-tanssiTeal-9)'}
    />
  ) : undefined;

  if (links?.length) {
    return (
      <NavLinkNext
        my={4}
        href={path}
        component={Link}
        className={classes.navLink}
        classNames={{
          label: classes.navLinkLabel,
          children: classes.navLinkChildren,
        }}
        label={label}
        defaultOpened={label !== 'Learn'}
        leftSection={icon}
      >
        {links.map((link) => {
          return (
            <NavLink
              key={link.label}
              pl={32}
              onClick={onClick}
              {...link}
              style={{ color: 'var(--mantine-color-gray-6)' }}
            />
          );
        })}
      </NavLinkNext>
    );
  }

  if (isOffsiteLink) {
    return (
      <OffsiteLink
        className={classes.offsiteLink}
        url={path}
        withIcon={false}
        tabIndex={-1}
        isWrapper
      >
        <NavLinkNext
          component={'button'}
          className={classes.navLink}
          classNames={{ label: classes.navLinkLabel }}
          label={label}
          active={isActive}
          leftSection={icon}
          onClick={onClick}
          my={4}
          {...other}
        />
      </OffsiteLink>
    );
  }

  return (
    <NavLinkNext
      href={path}
      component={Link}
      className={classes.navLink}
      classNames={{ label: classes.navLinkLabel }}
      label={label}
      active={isActive}
      leftSection={icon}
      onClick={onClick}
      {...other}
    />
  );
}
