import type { Asset, AssetAmount } from '@moonbeam-network/xcm-types';
import type { ChainKey } from './config.constants';

export interface BaseChainConfig {
  asset: Asset;
  decimals: number;
  genesisHash: string;
  name: string;
  isSymbioticChain: boolean;
  parachainId: number;
  ss58Format: number;
  ws: string;
  getTransactionUrl: (blockHash: string, txHash: string) => string;
  getAssetAmount: (amount?: bigint) => AssetAmount;
}

export interface Pallet<T extends 'Polkadot' | 'Restake'> {
  pallets: {
    registrar: T extends 'Polkadot' ? 'registrar' : 'containerRegistrar';
  };
}

export interface PolkadotRelayChainConfig extends BaseChainConfig {
  fees: {
    reserveParaId: AssetAmount;
    register: AssetAmount;
  };
}

export interface PolkadotChainConfig
  extends BaseChainConfig,
    Pallet<'Polkadot'> {
  key: ChainKey;
  relay: PolkadotRelayChainConfig;
  blockTime: number;
  blocksPerSession: number;
  http: string;
  runtime?: {
    block: number;
    session: number;
    version: 601;
  };
  fees: {
    register: AssetAmount;
    costPerBlock: AssetAmount;
    costPerSession: AssetAmount;
  };
}

export interface RestakeChainConfig
  extends PolkadotRelayChainConfig,
    Omit<PolkadotChainConfig, 'relay' | 'pallets'>,
    Pallet<'Restake'> {
  fees: {
    reserveParaId: AssetAmount;
    register: AssetAmount;
    costPerBlock: AssetAmount;
    costPerSession: AssetAmount;
  };
}

export type ChainConfig = PolkadotChainConfig | RestakeChainConfig;
export type RelayChainConfig = PolkadotRelayChainConfig | RestakeChainConfig;

export interface ChainConfigProps {
  config: ChainConfig;
}

export interface ChopsticksFork {
  endpoint: string;
  port: number;
  blockNumber: number;
}

export interface AppchainConfig extends BaseChainConfig {
  isEthereum: boolean;
  http: string;
  explorers: Explorer[];
}

export enum ExplorerName {
  PolkadotJs = 'polkadotJs',
  Evm = 'evm',
  SubscanDancebox = 'subscanDancebox',
  Blockscout = 'blockscout',
}

export interface Explorer {
  name: string;
  url?: string;
  logo?: string;
}

export type Explorers = Record<ExplorerName, Explorer>;

export type AppchainNodeUrls = Record<
  string,
  { bootnodes: string[]; rpc: string }
>;
