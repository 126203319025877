import { useCreateAppChainInfo } from '@/components/pages/CreateAppChain/state/create.hooks';
import { Step } from '@/components/pages/CreateAppChain/state/create.interfaces';
import type { RelayChainConfig } from '@/config';
import { useWalletNotifications } from '@/hooks/notifications/useWalletNotifications';
import { useCreateAppchainLogger } from '@/hooks/register/useCreateAppchainLogger';
import { useCreateAppchainFiles } from '@/hooks/useCreateAppchainFiles';
import { useApi } from '../../useApi';
import { useApiTransaction } from '../../useApiTransaction';

interface Register {
  isLoading: boolean;
  isReadyToRegister: boolean;
  onRegister: VoidFunction;
}

export function useRegisterInRelay(
  config: RelayChainConfig | undefined,
): Register {
  const api = useApi(config?.ws);
  const { paraId } = useCreateAppChainInfo();
  const { logInfo } = useCreateAppchainLogger();
  const { transactionSuccess } = useWalletNotifications(config);
  const { genesisWasm, genesisState, haveFiles } = useCreateAppchainFiles();

  const { isLoading, send } = useApiTransaction({
    tx: api?.tx.registrar.register,
    config,
    notify: { notifyOnSuccess: false },
    onSuccess: (txHash, blockHash) => {
      transactionSuccess({
        txHash,
        blockHash,
        title: 'Network was successfully registered',
        message: 'Network was successfully registered in the Relay Chain.',
      });
      logInfo('Network was successfully registered.', Step.Register);
    },
  });

  return {
    isLoading,
    isReadyToRegister: haveFiles,
    // @ts-expect-error - api takes types from @tanssi/api-augment
    onRegister: () => genesisWasm && send(paraId, genesisState, genesisWasm),
  };
}
