import { useMediaQuery } from '@/hooks/useMediaQuery';
import { isDefined } from '@/utils/common';
import { Box, Title, type TitleProps } from '@mantine/core';
import type { ReactNode } from 'react';

interface Props extends TitleProps {
  children?: ReactNode;
}

export function MainTitle({ children, ...others }: Props) {
  const { xs } = useMediaQuery();

  if (!isDefined(xs)) {
    return <Box h={57} />; // title height - not using skeleton because don't know the length of the title
  }

  return (
    <Title
      size={xs ? 44 : 36}
      fw={800}
      c={'white'}
      {...others}
      style={{ ...others.style, fontFamily: 'Epilogue Extra Bold, sans-serif' }}
    >
      {children}
    </Title>
  );
}
