import { SidebarButtons } from '@/components/Sidebar/SidebarButtons';
import { SudoSidebarWrapper } from '../SudoSidebarWrapper';
import {
  ManageAppchainTab,
  getTabInfo,
} from '../state/ManageAppchain.constants';

const buttons = [ManageAppchainTab.Runtime].map(getTabInfo);

export function Maintenance() {
  return (
    <SudoSidebarWrapper
      tab={ManageAppchainTab.Maintenance}
      goBack={ManageAppchainTab.Management}
    >
      <SidebarButtons isSudo buttons={buttons} />
    </SudoSidebarWrapper>
  );
}
