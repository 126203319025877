import { stakingChainKeyAtom } from '@/components/pages/Staking/state/staking.atoms';
import type { StakingChainKey } from '@/components/pages/Staking/state/staking.interface';
import { isStakingChainKeyValid } from '@/components/pages/Staking/state/staking.utils';
import {
  ChainKey,
  chains,
  dancebox,
  danceboxForkConfig,
  isLocal,
  isTest,
  local,
} from '@/config';
import { useIsDancelightEnabledFlag } from '@/hooks/flags/useIsDancelightEnabledFlag';
import { getChainKeyFromQuery } from '@/state/chain/chain.utils';
import { useAtom } from 'jotai';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';

const defaultConfig = isTest ? danceboxForkConfig : isLocal ? local : undefined;

export function useStakingChainConfig() {
  const { query } = useRouter();
  const { isEnabled } = useIsDancelightEnabledFlag();
  const [selectedChainKey, setSelectedChainKey] = useAtom(stakingChainKeyAtom);

  const param = getChainKeyFromQuery(query);
  const isValid = isStakingChainKeyValid(param);
  const paramKey = isValid ? param : undefined;

  return {
    selectedChainKey,
    setSelectedChainKey: useCallback(
      (key: StakingChainKey) => {
        setSelectedChainKey(
          key === ChainKey.Dancelight && isEnabled
            ? ChainKey.Dancelight
            : ChainKey.Dancebox,
        );
      },
      [isEnabled, setSelectedChainKey],
    ),
    config: useMemo(
      () =>
        defaultConfig ||
        chains.get(paramKey || (selectedChainKey as ChainKey)) ||
        dancebox,
      [selectedChainKey, paramKey],
    ),
  };
}
