export function isDefined<T>(param: T | null | undefined): param is T {
  return param !== undefined && param !== null;
}

export function areDefined<T extends unknown[]>(...params: T): boolean {
  return params.every(isDefined);
}

export function isDefinedRecursive(params: unknown[]): boolean {
  return params.every((param) => {
    if (Array.isArray(param)) {
      return isDefinedRecursive(param);
    }

    if (typeof param === 'object') {
      return isDefinedRecursive(Object.values(param || {}));
    }

    return isDefined(param);
  });
}

export function capitalize(text: string): string {
  if (!text) {
    return '';
  }

  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

export function chunk<T>(array: T[], size: number): T[][] {
  const result: T[][] = [];

  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }

  return result;
}

export function sanitizeString(str: string | undefined): string | undefined {
  if (!str) {
    return;
  }

  return str
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#x27;')
    .replace(/\//g, '&#x2F;')
    .trim();
}

export function pick<T extends object, K extends keyof T>(
  obj: T,
  keys: K[],
): Pick<T, K> {
  const result = {} as Pick<T, K>;
  keys.forEach((key) => {
    if (key in obj) {
      result[key] = obj[key];
    }
  });
  return result;
}
