import type { UnionToTuple } from 'type-fest';

export const APPCHAINS_LIMIT = 30;

export const DEMO_CHAIN_ID = 5678;
export const DEMO_APPCHAIN_PARA_ID = 3001;

export const BASE_URL = {
  local: 'http://localhost:4000',
  test: `http://localhost:${process.env.CI ? 3000 : 4000}`,
  sandbox: 'https://apps.tanssi-apps.xyz',
  production: 'https://apps.tanssi.network',
} as const;

export enum AppchainsEnum {
  All = 'All',
  EVM = 'EVM',
  Substrate = 'Substrate',
  Snaps = 'Snaps',
}

export enum ChainKey {
  Stagebox = 'stagebox',
  Dancebox = 'dancebox',
  Flashbox = 'flashbox',
  Stagelight = 'stagelight',
  Dancelight = 'dancelight',
  Starlight = 'starlight',
  Demo = 'demo',
  Local = 'local',
  LocalStagelight = 'localStagelight',
}
export type ChainKeyTuple = UnionToTuple<ChainKey>;
export const CHAIN_KEYS = Object.values(ChainKey) as ChainKeyTuple;

export const DEV_APPCHAINS = [
  ChainKey.Local,
  ChainKey.LocalStagelight,
  ChainKey.Stagebox,
  ChainKey.Stagelight,
];

export enum ForkKey {
  DanceboxStaking = 'dancebox-staking',
  DanceboxProxyStaking = 'dancebox-proxy-staking',
  DanceboxRuntime = 'dancebox-runtime',
  Stagelight = 'stagelight',
}
