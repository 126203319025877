import { useManageAppchain } from '@/components/pages/Dashboard/ManageAppchain/state';
import type { ChainConfig } from '@/config';
import { useWalletNotifications } from '@/hooks/notifications/useWalletNotifications';
import { useApi, useApiTransaction } from '@/hooks/polkadot';

export function useSetAppchainTip(config: ChainConfig | undefined) {
  const api = useApi(config?.ws);
  const { closeAppchainSidebar } = useManageAppchain();
  const { transactionSuccess } = useWalletNotifications(config);

  return useApiTransaction({
    tx: api?.tx.servicesPayment.setMaxTip,
    config,
    notify: { notifyOnSuccess: false },
    omitProxy: true,
    onSuccess: (txHash, blockHash) => {
      closeAppchainSidebar();
      transactionSuccess({
        txHash,
        blockHash,
        title: 'You have successfully set the tip',
        message: 'Your tip has been set.',
      });
    },
  });
}
