import type { StakingChainKey } from '@/components/pages/Staking/state/staking.interface';
import { ChainKey } from '@/config';

export function isStakingChainKeyValid(
  param: string | undefined,
): param is StakingChainKey {
  return (
    !!param &&
    [ChainKey.Dancebox, ChainKey.Dancelight].includes(param as ChainKey)
  );
}
