import { useAppchainSudo } from '@/hooks/polkadot/appchain';
import { Group, Text, UnstyledButton } from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';
import { Badge } from '../Badge';
import classes from './SidebarButton.module.css';

export interface Props {
  title: string;
  description: string;
  isDisabled?: boolean;
  badgeText?: string;
  isSudo?: boolean;
  onClick: () => void;
}

export function SidebarButton({
  title,
  description,
  isDisabled,
  badgeText,
  isSudo = false,
  onClick,
}: Props) {
  const { hasSudoRights } = useAppchainSudo();
  const isButtonDisabled = isDisabled || (isSudo && !hasSudoRights);

  return (
    <UnstyledButton
      py={10}
      px={'md'}
      className={classes.button}
      disabled={isButtonDisabled}
      onClick={onClick}
    >
      {badgeText && (
        <Badge
          pos={'absolute'}
          top={'var(--mantine-spacing-xs)'}
          right={'var(--mantine-spacing-xs)'}
          tt={'none'}
          fw={400}
        >
          {badgeText}
        </Badge>
      )}
      <Text size={'lg'} opacity={isButtonDisabled ? 0.5 : 1}>
        {title}
      </Text>
      <Group
        mt={3}
        justify={'space-between'}
        align={'end'}
        wrap={'nowrap'}
        opacity={isButtonDisabled ? 0.5 : 1}
      >
        <Text size={'xs'} c={'gray.6'}>
          {description}
        </Text>
        <IconArrowRight
          size={15}
          stroke={1.5}
          color={'var(--mantine-color-gray-6)'}
          style={{ flexShrink: 0 }}
        />
      </Group>
    </UnstyledButton>
  );
}
