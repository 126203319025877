import { ConnectWallet } from '@/components/ConnectWallet';
import { useIsProxyPageEnabledFlag } from '@/hooks/flags/useIsProxyPageEnabledFlag';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import {
  Anchor,
  AppShell,
  type AppShellHeaderProps,
  Burger,
  Button,
  Group,
  useMantineTheme,
} from '@mantine/core';
import { IconPolygon } from '@tabler/icons-react';
import Link from 'next/link';

interface Props extends AppShellHeaderProps {
  width?: string;
  opened: boolean;
  toggle: () => void;
}

export function Header({ right, opened, toggle }: Props) {
  const { md } = useMediaQuery();
  const { isEnabled: isProxyViewEnabled } = useIsProxyPageEnabledFlag();
  const theme = useMantineTheme();

  return (
    <AppShell.Header
      h={'var(--header-height)'}
      right={right}
      withBorder={md === false}
      px={'lg'}
      bg={'dark.9'}
    >
      <Group
        justify={'space-between'}
        align={'center'}
        wrap={'nowrap'}
        h={'100%'}
      >
        <Burger opened={opened} onClick={toggle} hiddenFrom={'md'} />
        <Group justify={'end'} gap={'md'} h={'100%'} w={'100%'}>
          {isProxyViewEnabled && (
            <Anchor component={Link} href={'/proxy'}>
              <Button
                variant={'light'}
                leftSection={<IconPolygon size={20} stroke={1.5} />}
              >
                {md ? 'Proxy Management' : 'Proxies'}
              </Button>
            </Anchor>
          )}
          <ConnectWallet />
        </Group>
      </Group>
    </AppShell.Header>
  );
}
