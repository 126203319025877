import { ActionButton } from '@/components/ActionButton';
import { CopyIcon } from '@/components/CopyIcon';
import {
  UpgradeStatus,
  useAppchainUpgradeRuntime,
} from '@/hooks/polkadot/appchain';
import { ellipsis } from '@/utils/address';
import { Stack } from '@mantine/core';
import { Badge, Box, Group, Text, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconCheck,
  IconChevronDown,
  IconChevronUp,
  IconRefresh,
} from '@tabler/icons-react';

interface Props {
  from: number;
  to: number;
  status: UpgradeStatus;
  isLast: boolean;
  hasSudoRights: boolean;
  txHash?: string;
}

export function RuntimeUpgradeRow({
  from,
  to,
  status,
  isLast,
  txHash,
  hasSudoRights,
}: Props) {
  const theme = useMantineTheme();
  const [isExpanded, { toggle }] = useDisclosure(true);
  const { isLoading, send } = useAppchainUpgradeRuntime(from, to);

  return (
    <Box key={`${from}-${to}`}>
      <Group
        my={8}
        justify="space-between"
        align="center"
        wrap="nowrap"
        onClick={toggle}
      >
        <Group gap={'xs'}>
          {status === UpgradeStatus.Completed && (
            <Badge bg={'others.colors.lightGreen'} size="sm" circle>
              <IconCheck size={12} stroke={1.5} color={'white'} />
            </Badge>
          )}

          {status === UpgradeStatus.Next &&
            (isLoading ? (
              <Badge bg={'others.colors.yellow'} size="sm" circle>
                <IconRefresh size={12} stroke={1.5} color={'white'} />
              </Badge>
            ) : (
              <Box
                w={18}
                h={18}
                style={{
                  border: `1px solid ${theme.colors.tanssiTeal[9]}`,
                  borderRadius: '100%',
                }}
              />
            ))}

          {status === UpgradeStatus.Available && (
            <Box
              w={18}
              h={18}
              style={{
                border: `1px solid ${theme.colors.gray[6]}`,
                borderRadius: '100%',
              }}
            />
          )}

          <Text c={'gray.6'} size="sm">
            Upgrade:{' '}
            <Text size="sm" span>
              {from} to {to}
            </Text>
          </Text>
        </Group>

        {isExpanded ? (
          <IconChevronUp size={15} color="white" />
        ) : (
          <IconChevronDown size={15} color="white" />
        )}
      </Group>

      <Stack
        ml={7}
        h={60}
        pl={18}
        justify="center"
        gap={0}
        style={{
          display: isExpanded ? 'flex' : 'none',
          borderLeft: isLast ? 'none' : '1px solid white',
        }}
      >
        {status === UpgradeStatus.Completed && (
          <>
            <Text size="sm" c={'gray.6'}>
              Tx Hash:
            </Text>
            <Group justify="space-between" align={'center'} gap={5}>
              <Text size="sm">{ellipsis(txHash, 16, 12)}</Text>
              <CopyIcon value={txHash} size={18} color={'gray.6'} />
            </Group>
          </>
        )}

        {status !== UpgradeStatus.Completed && (
          <ActionButton
            fullWidth
            disabled={status === UpgradeStatus.Available || !hasSudoRights}
            withArrow={false}
            size="sm"
            isLoading={status === UpgradeStatus.Next && isLoading}
            onClick={send}
          >
            {status === UpgradeStatus.Next && isLoading
              ? 'Upgrading'
              : 'Upgrade'}
          </ActionButton>
        )}
      </Stack>
    </Box>
  );
}
