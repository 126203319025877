import { type ChainKey, chains, dancebox, getRelay } from '@/config';
import { useChainKey } from '@/state/chain';
import { useMemo } from 'react';

export function useChainConfig(key?: ChainKey) {
  const chainKey = useChainKey();

  return useMemo(() => {
    const config = chains.get(key || chainKey) || dancebox;
    const relay = getRelay(config);

    return { config, relay };
  }, [key, chainKey]);
}
