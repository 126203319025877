import { ActionButton } from '@/components/ActionButton';
import { SidebarHeader } from '@/components/Sidebar';
import { TokenAmount } from '@/components/TokenAmount';
import { TokenAmountInput } from '@/components/TokenAmountInput';
import { useManageAppchainState } from '@/components/pages/Dashboard/ManageAppchain/state';
import { ManageAppchainTab } from '@/components/pages/Dashboard/ManageAppchain/state/ManageAppchain.constants';
import { useSetAppchainTip } from '@/hooks/polkadot/appchain';
import { useAppchainTip } from '@/hooks/polkadot/common';
import { formNumberSchema } from '@/schema/number.schema';
import { Box, Center, Group, Text } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { toBigInt } from '@moonbeam-network/xcm-utils';
import { z } from 'zod';

interface Props {
  closeOnBack: boolean;
}

export function BlockProductionTip({ closeOnBack }: Props) {
  const { paraId, config } = useManageAppchainState();
  const currentTip = useAppchainTip(paraId, config);
  const { send, isLoading } = useSetAppchainTip(config);

  const form = useForm<{ amount: string }>({
    validate: zodResolver(
      z.object({
        amount: formNumberSchema(z.coerce.number().positive()),
      }),
    ),
    initialValues: { amount: '' },
  });

  const onSubmit = async () => {
    if (!form.values.amount || !config || !paraId) {
      return;
    }

    send(paraId, toBigInt(form.values.amount, config.decimals));
  };

  return (
    <>
      <SidebarHeader
        title={'Set Max Tip'}
        goBack={closeOnBack ? undefined : ManageAppchainTab.BlockProduction}
      />
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Box
          py={'md'}
          px={'md'}
          style={{ borderBottom: '1px solid var(--mantine-color-gray-9)' }}
        >
          <Group mt={5} justify={'space-between'}>
            <Text c={'gray.6'} size={'sm'}>
              {'Current Tip:'}
            </Text>
            <TokenAmount
              amount={currentTip}
              symbolColor={'gray.6'}
              data-testid={'projected-auto-stake'}
            />
          </Group>
          <TokenAmountInput
            mt={'sm'}
            label={'Enter amount'}
            labelStyles={{ fontSize: 14 }}
            disabled={isLoading}
            value={form.values.amount}
            symbol={config?.asset.originSymbol || ''}
            {...form.getInputProps('amount')}
          />
        </Box>
        <Center>
          <ActionButton
            type={'submit'}
            mt={'md'}
            mx={'sm'}
            w={'100%'}
            isLoading={isLoading}
            withArrow={false}
          >
            {'Set Max Tip'}
          </ActionButton>
        </Center>
      </form>
    </>
  );
}
