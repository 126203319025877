import { SidebarButton, SidebarStack } from '@/components/Sidebar';
import { useManageAppchain } from '@/components/pages/Dashboard/ManageAppchain/state';
import type { TabInfo } from '@/components/pages/Dashboard/ManageAppchain/state/ManageAppchain.constants';
import type { StackProps } from '@mantine/core';

interface Props extends StackProps {
  isSudo?: boolean;
  buttons: TabInfo[];
}

export function SidebarButtons({ isSudo, buttons, ...others }: Props) {
  const { switchAppchainSidebarTab } = useManageAppchain();

  return (
    <SidebarStack {...others}>
      {buttons.map(({ title, description, tab, isDisabled }) => (
        <SidebarButton
          key={title}
          onClick={() => switchAppchainSidebarTab(tab)}
          title={title}
          description={description}
          isDisabled={isDisabled}
          isSudo={isSudo}
        />
      ))}
    </SidebarStack>
  );
}
